<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading">Apply for loan</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-layout wrap justify-start>
          <!-- <v-flex xs12 pa-4>
            <v-spacer></v-spacer>
          </v-flex> -->

          <v-flex xs12 align-self-center fill-height pa-0>
            <v-card tile flat>
              <v-form v-model="addcat2" ref="addcat2">
                <v-layout wrap v-if="appUser">
                  <v-flex xs12 sm6 align-self-center text-left pa-2 v-if="appUser.name">
                    <v-text-field
                      v-model="appUser.name"
                      label="Name"
                      :rules="[rules.required]"
                      required
                      disabled
                      hide-details
                      outlined
                      type="text"
                      @wheel="$event.target.blur()"
                      dense
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 align-self-center text-left pa-2>
                    <v-text-field
                      v-model="designation"
                      label="Designation"
                      :rules="[rules.required]"
                      required
                      hide-details
                      outlined
                      type="text"
                      @wheel="$event.target.blur()"
                      dense
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 align-self-center text-left pa-2>
                    <v-text-field
                      v-model="grade"
                      label="Grade"
                      :rules="[rules.required]"
                      required
                      hide-details
                      outlined
                      type="text"
                      @wheel="$event.target.blur()"
                      dense
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 align-self-center text-left pa-2>
                    <v-text-field
                      v-model="programmeOrDivision"
                      label="Programme/ Division"
                      :rules="[rules.required]"
                      required
                      hide-details
                      outlined
                      type="text"
                      @wheel="$event.target.blur()"
                      dense
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 align-self-center text-left pa-2>
                    <v-text-field
                      v-model="office"
                      label="Office"
                      :rules="[rules.required]"
                      required
                      hide-details
                      outlined
                      type="text"
                      @wheel="$event.target.blur()"
                      dense
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm5 align-self-center text-left pa-2>
                    <v-text-field
                      v-model="loanAmount"
                      label="Loan amount"
                      :rules="[rules.required]"
                      required
                      hide-details
                      outlined
                      type="number"
                      @wheel="$event.target.blur()"
                      dense
                    ></v-text-field>
                  </v-flex>
                  <!-- upload documents -->
                   <v-flex xs1 align-self-center>
                     <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
                        <v-icon large
                          color="#408BFF" v-bind="attrs"
          v-on="on"
                          @click="$refs.files.click()"
                          >mdi-cloud-upload</v-icon
                        >
                         </template>
   
                        <span style="font-size:12px;">Upload supporting documents if present</span>
                      <!-- </v-flex>
                      <v-flex xs1 pb-5> -->
                        <!-- <span class="con4">upload Document</span>
                        &nbsp; -->
                        <input
                          v-show="false"
                          id="file1"
                          ref="files"
                          type="file"
                          multiple
                          @change="browseCover"
                        />
                         </v-tooltip>
                      </v-flex>
                       <v-flex xs12 py-3 text-left v-if="documentFile1.length>0">
                        <!-- <v-chips> -->
                          <v-chip v-for="(item, i) in documentFile1" :key="i"
                            @click="documentFile1"
                            dark
                            color="green"
                            style="margin-right: 6px;margin-b"
                            
                          >{{ item.name }}<v-icon class="pl-2" small v-if="chip"
                            @click="close(item,i)">mdi-close-circle-outline</v-icon>
                            
                          </v-chip>
                        <!-- </v-chips> -->
                      </v-flex>
                  <!---------------------->
                  <v-flex xs12 sm6 align-self-center text-left pa-2>
                    <v-text-field
                      v-model="tobeReleasedBy"
                      label="To be released by"
                      :rules="[rules.required]"
                      required
                      hide-details
                      outlined
                      type="text"
                      @wheel="$event.target.blur()"
                      dense
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 align-self-center text-left pa-2 v-if="userJoinDate">
                    <v-text-field
                      v-model="userJoinDate"
                      label="Date of Joining WTI"
                      :rules="[rules.required]"
                      required
                      disabled
                      hide-details
                      outlined
                      type="text"
                      @wheel="$event.target.blur()"
                      dense
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 align-self-center text-left pa-2>
                    <v-text-field
                      v-model="presentNetSalaryPerMonth"
                      label="Present net salary/ month"
                      :rules="[rules.required]"
                      required
                      hide-details
                      outlined
                      type="number"
                      @wheel="$event.target.blur()"
                      dense
                    ></v-text-field>
                  </v-flex>
                   <v-flex xs6 pa-2>
                        <v-text-field
                      v-model="purposeOfLoan"
                      label="Purpose of loan"
                      hide-details
                      outlined
                      type="type"
                      @wheel="$event.target.blur()"
                      dense
                    ></v-text-field>
                      </v-flex>
                <v-flex xs6 pa-2 align-self-start>
                <v-layout wrap>
                  <v-flex xs12 align-self-center text-left pa-2>
                    <span
                      >As on date, iIs there any advance/ loan amount
                      outstanding against you?</span
                    >
                  </v-flex>
                   <v-flex xs4 align-self-center text-left pa-2>
                    <v-radio-group v-model="radios" row hide-details>
                      <v-radio label="Yes" value="yes"></v-radio>
                      <v-radio label="No" value="no"></v-radio>
                    </v-radio-group>
                   </v-flex>
                    <v-flex xs8 align-self-center text-left pa-2 v-if="radios=='yes'">
                     <v-text-field
                      v-model="advanceLoanAmount"
                      label="Amount"
                      hide-details
                      outlined
                      type="number"
                      @wheel="$event.target.blur()"
                      dense
                    ></v-text-field>
                  </v-flex>
                  <!-- <v-flex xs12 sm6 align-self-center text-left pa-2>
                    <v-select
                      v-model="type"
                      :items="typeList"
                      :rules="[rules.required]"
                      hide-details
                      outlined
                      dense
                      label="Choose type"
                    >
                    </v-select>
                  </v-flex>
                
                  <v-flex xs12 align-self-center text-left pa-2>
                    <v-textarea
                      v-model="description"
                      label="Description"
                      hide-details
                      outlined
                      type="number"
                      dense
                    ></v-textarea>
                  </v-flex> -->
                <!-- </v-layout>
                <v-layout wrap> -->
                
                  
                </v-layout>
                </v-flex>
                    <!-- <v-flex xs6 pa-2 align-self-start>
         <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="Date"
                    outlined
                    hide-details
                    readonly
                    dense
                    class="rounded-0"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  no-title
                  scrollable
                  @change="$refs.menu.save(date)"
                >
                  <v-spacer></v-spacer>
                </v-date-picker>
              </v-menu>
          </v-flex> -->
                </v-layout>
                <v-layout wrap>
                  <v-flex xs12 align-self-center text-center pa-2>
                    <v-layout wrap justify-center py-4>
                      <v-flex xs12 sm6 md4 lg3 align-self-center px-4>
                        <v-btn
                          color="#005f32"
                          dark
                          tile
                          large
                          class="rounded-xl"
                          block
                          @click="applyLoan()"
                        >
                          Submit
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-card>
          </v-flex>
        </v-layout>

        <!-- </v-card> -->
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      chip:true,
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      pageCount: 1,
      appLoading: false,
      joindate:null,
      //add
      name:null,
      designation:null,
      grade:null,
      programmeOrDivision:null,
      office:null,
      loanAmount:null,
      tobeReleasedBy:null,
      //fileupload
      documentFile1: [],
      docsdata: new FormData(),

      // dateOfJoining:null,
      presentNetSalaryPerMonth:null,
      // advanceLoanAmountExist: false,
      advanceLoanAmount:null,
      purposeOfLoan:null,
      //
      addcat: false,
      addcat2: false,
      rules: {
        required: (value) => !!value || "Required.",
      },

      // typeList: ["Salary Advance", "Loan"],
      amount: null,
      radios: null,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
    };
  },

  mounted() {
    //  if(this.appUser.name!='Super Admin')
    // this.joindate = this.appUser.dateofjoining.slice(0,10)
  },
computed: {
  appUser(){
    return this.$store.state.userData;
  },
    userJoinDate() {
      return this.$store.state.userData.dateofjoining;
    },
    userId(){
      return this.$store.state.userData._id;
    },
  },
  methods: {
    close(item,i){
this.documentFile1.splice(i,1)
    },
    validateInput() {
      if (!this.type) {
        this.msg = "Please choose type";
        this.showsnackbar = true;
        return;
      } else if (!this.amount) {
        this.msg = "Please provide amount";
        this.showsnackbar = true;
        return;
      } else {
        this.applyLoan();
      }
    },
      browseCover(event) {
      var got = event.target.files;
      if (got.length < 5) {
        for (let i = 0; i < got.length; i++) {
          this.documentFile1.push(got[i]);
        }
        console.log("file isss", this.documentFile1);
      } else {
        this.msg = "Maximum 3 can be uploaded";
        this.showSnackBar = true;
      }
    },
  
    applyLoan() {
      
      axios({
        url: "/loan/add",
        method: "POST",
        data: {
          employeeId:this.userId,
          name: this.appUser.name,
          designation: this.designation,
          grade: this.grade,
          programmeOrDivision: this.programmeOrDivision,
          office: this.office,
          loanAmount: this.loanAmount,
          tobeReleasedBy: this.tobeReleasedBy,
          dateOfJoining: this.userJoinDate,
          presentNetSalaryPerMonth: this.presentNetSalaryPerMonth,
          advanceLoanAmountExist:this.radios,
          advanceLoanAmount: this.advanceLoanAmount,
          purposeOfLoan: this.purposeOfLoan,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status==true) {
            this.msg =response.data.msg;
            this.showsnackbar = true;
            if (this.documentFile1.length > 0) {
            console.log("hiii");
            this.documentID = response.data.data._id;
            console.log("documentID",this.documentID);
            console.log("documentfile",this.documentFile1)
            if (this.documentID) {
              this.docsupload();
            } else {
              alert("document updated");
            }
            }
            else{
               this.$router.push("/MyLoanApplications");
            }
            // this.$router.push("/MyLoanApplications");
            // this.clearOff();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
      
     
    },
     docsupload() {
      for (var i = 0; i < this.documentFile1.length; i++) {
        this.docsdata.append("document", this.documentFile1[i]);
      }
      this.docsdata.append("id", this.documentID);

      this.appLoading = true;
      axios({
        url: "/upload/loandocuments",
        method: "post",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: this.docsdata,
      })
        .then((response) => {
          this.appLoading = false;
          // this.dialog = false;
          this.documentFile1 = [];
          this.docsdata = new FormData();
          this.msg = response.data.msg;
          this.$router.push("/MyLoanApplications");
            this.clearOff();
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
     formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " , " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;

      return strTime;
    },
    clearOff() {
      this.type = null;
      this.amount = null;
      this.description = null;
    },
  },
};
</script>
